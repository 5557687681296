import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

class UpdatedvrResult extends Component {
  state = {
    stdOut: "",
    stdErr: "",
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      stdOut: nextProps.stdOut,
      status: nextProps.status,
      stdErr: nextProps.stdErr,
    };
  }

  parseOutput = (output) => {
    const mainCameras = [];
    const subCameras = [];
    const sections = output.split("-----------").filter((c) => c.trim());

    sections.forEach((section) => {
      const sectionLines = section
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line);

      const idLine = sectionLines.find((line) => line.startsWith("ID Channel"));
      if (idLine) {
        const id = parseInt(idLine.split(": ")[1], 10);
        const details = {
          id,
          resolution: this.getLineValue(sectionLines, "Resolution"),
          typeBandePassante: this.getLineValue(sectionLines, "Type bande passante"),
          imageParSeconde: this.getLineValue(sectionLines, "Image par seconde"),
          debitBinaireMax: this.getLineValue(sectionLines, "Debit binaire max"),
          encodageVideo: this.getLineValue(sectionLines, "Encodage vidéo"),
          motionDetect: this.getLineValue(sectionLines, "Motion Detect"),
          encrypt: this.getLineValue(sectionLines, "Encrypt"),
        };

        if (id % 100 === 1) {
          mainCameras.push(details);
        } else {
          subCameras.push(details);
        }
      }
    });

    return { mainCameras, subCameras };
  };

  parseUpdateOutput = (output) => {
    const sections = output.split("***").map((section) => section.trim());

    const oldCameras = [];
    const newCameras = [];

    sections.forEach((section) => {
      const cameraSections = section.split(/debut|fin/).map((s) => s.trim());

      if (cameraSections.length >= 3) {
        const [before, , after] = cameraSections;

        const parseCameras = (cameraSection) => {
          const cameras = cameraSection.split("-----------").filter((c) => c.trim());
          return cameras
            .map((camera) => {
              const lines = camera
                .split("\n")
                .map((line) => line.trim())
                .filter((line) => line);

              const idLine = lines.find((line) => line.startsWith("ID Channel"));
              if (idLine) {
                const id = parseInt(idLine.split(": ")[1], 10);
                const resolutionLine = lines.find((line) => line.startsWith("Resolution"));
                const typeBandePassanteLine = lines.find((line) => line.startsWith("Type bande passante"));
                const imageParSecondeLine = lines.find((line) => line.startsWith("Image par seconde"));
                const debitBinaireMaxLine = lines.find((line) => line.startsWith("Debit binaire max"));
                const encodageVideoLine = lines.find((line) => line.startsWith("Encodage vidéo"));
                const motionDetectLine = lines.find((line) => line.startsWith("Motion Detect"));
                const encryptLine = lines.find((line) => line.startsWith("Encrypt"));

                return {
                  id,
                  resolution: resolutionLine ? resolutionLine.split(": ")[1] : "N/A",
                  typeBandePassante: typeBandePassanteLine ? typeBandePassanteLine.split(": ")[1] : "N/A",
                  imageParSeconde: imageParSecondeLine ? parseFloat(imageParSecondeLine.split(": ")[1]) : "N/A",
                  debitBinaireMax: debitBinaireMaxLine ? debitBinaireMaxLine.split(": ")[1] : "N/A",
                  encodageVideo: encodageVideoLine ? encodageVideoLine.split(": ")[1] : "N/A",
                  motionDetect: motionDetectLine ? motionDetectLine.split(": ")[1] : "N/A",
                  encrypt: encryptLine ? encryptLine.split(": ")[1] : "N/A",
                };
              }
              return null;
            })
            .filter(Boolean);
        };

        oldCameras.push(...parseCameras(before));
        newCameras.push(...parseCameras(after));
      }
    });

    return { oldCameras, newCameras };
  };

  getLineValue = (lines, key) => {
    const line = lines.find((ln) => ln.startsWith(key));
    return line ? line.split(": ")[1] : "N/A";
  };

  getResolutionColor = (resolution) => {
    const width = parseInt(resolution.split("x")[0], 10);
    if (width >= 970 || width < 640) {
      return "red";
    }
    return "green";
  };

  getEncodageColor = (encodageVideo) => {
    if (encodageVideo.includes("264")) {
      return "green";
    }
    return "red";
  };

  getFpsColor = (imageParSeconde) => {
    if (parseInt(imageParSeconde) > 5 && parseInt(imageParSeconde) < 16) {
      return "green";
    }
    return "red";
  };

  getBitratesColor = (debitBinaireMax) => {
    if (parseInt(debitBinaireMax) >= 500 && parseInt(debitBinaireMax) < 1025) {
      return "green";
    }
    return "red";
  };

  getTypebandeColor = () => {
    return "green";
  };

  parsePossibilities = (output) => {
    const primaryStart = output.indexOf("// Flux primaire //");
    const secondaryStart = output.indexOf("// Flux secondaire //");

    if (primaryStart === -1 || secondaryStart === -1) return { primary: null, secondary: null };

    const primaryEnd = secondaryStart;
    const secondaryEnd = output.indexOf(";", secondaryStart);

    const primarySection = output.slice(primaryStart, primaryEnd).trim();
    const secondarySection = output.slice(secondaryStart, secondaryEnd).trim();

    const parseSection = (section) => {
      const lines = section
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line);
      const possibilities = {};
      lines.forEach((line) => {
        const [key, value] = line.split(" : ");
        if (key && value) {
          possibilities[key] = value;
        }
      });
      return possibilities;
    };

    return {
      primary: parseSection(primarySection),
      secondary: parseSection(secondarySection),
    };
  };

  renderPossibilitiesCard = (title, possibilities) => (
    <Card className="mb-2">
      <CardHeader>
        <CardTitle className="text-center" tag="h5">
          {title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        {possibilities &&
          Object.entries(possibilities).map(([key, value]) => (
            <p key={key}>
              <strong>{key}:</strong> {value}
            </p>
          ))}
      </CardBody>
    </Card>
  );

  renderCameraCard = (camera, isIpCamera = false) => (
    <Card key={camera.id} className="mb-2">
      <CardHeader>
        <CardTitle className="text-center" tag="h5">
          Camera {isIpCamera ? `IP ${camera.cameraIp} - ` : ""}
          {camera.id}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <p>
          <strong>Resolution:</strong>{" "}
          <span style={{ color: this.getResolutionColor(camera.resolution) }}>{camera.resolution}</span>
        </p>
        <p>
          <strong>Type bandwidth:</strong>{" "}
          <span style={{ color: this.getTypebandeColor(camera.typeBandePassante) }}>{camera.typeBandePassante}</span>
        </p>
        <p>
          <strong>Fps:</strong>{" "}
          <span style={{ color: this.getFpsColor(camera.imageParSeconde) }}>{camera.imageParSeconde}</span>
        </p>
        <p>
          <strong>Bitrates:</strong>{" "}
          <span style={{ color: this.getBitratesColor(camera.debitBinaireMax) }}>{camera.debitBinaireMax}</span>
        </p>
        <p>
          <strong>Video encoding:</strong>{" "}
          <span style={{ color: this.getEncodageColor(camera.encodageVideo) }}>{camera.encodageVideo}</span>
        </p>
        {camera.encrypt && (
          <p>
            <strong>Encrypt:</strong> {camera.encrypt}
          </p>
        )}
        {camera.motionDetect && (
          <p>
            <strong>Motion Detect:</strong> {camera.motionDetect}
          </p>
        )}
      </CardBody>
    </Card>
  );

  renderUpdatedCameraCard = (oldCamera, newCamera, isIpCamera = false) => (
    <Card key={newCamera.id} className="mb-2">
      <CardHeader>
        <CardTitle className="text-center" tag="h5">
          Camera {isIpCamera ? `IP ${newCamera.cameraIp} - ` : ""}
          {newCamera.id}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <p>
          <strong>Resolution:</strong>{" "}
          <span style={{ color: oldCamera.resolution !== newCamera.resolution ? "green" : "inherit" }}>
            {newCamera.resolution}
          </span>
        </p>
        <p>
          <strong>Type bandwidth:</strong>{" "}
          <span style={{ color: oldCamera.typeBandePassante !== newCamera.typeBandePassante ? "green" : "inherit" }}>
            {newCamera.typeBandePassante}
          </span>
        </p>
        <p>
          <strong>Fps:</strong>{" "}
          <span style={{ color: oldCamera.imageParSeconde !== newCamera.imageParSeconde ? "green" : "inherit" }}>
            {newCamera.imageParSeconde}
          </span>
        </p>
        <p>
          <strong>Bitrates:</strong>{" "}
          <span style={{ color: oldCamera.debitBinaireMax !== newCamera.debitBinaireMax ? "green" : "inherit" }}>
            {newCamera.debitBinaireMax}
          </span>
        </p>
        <p>
          <strong>Video encoding:</strong>{" "}
          <span style={{ color: oldCamera.encodageVideo !== newCamera.encodageVideo ? "green" : "inherit" }}>
            {newCamera.encodageVideo}
          </span>
        </p>
        {newCamera.encrypt && (
          <p>
            <strong>Encrypt:</strong> {newCamera.encrypt}
          </p>
        )}
        {newCamera.motionDetect && (
          <p>
            <strong>Motion Detect:</strong>{" "}
            <span style={{ color: oldCamera.motionDetect !== newCamera.motionDetect ? "green" : "inherit" }}>
              {newCamera.motionDetect}
            </span>
          </p>
        )}
      </CardBody>
    </Card>
  );

  renderCameraCards = (mainCameras, subCameras) => {
    const cards = [];
    for (let i = 0; i < mainCameras.length; i++) {
      cards.push(
        <Row key={i} className="mb-4">
          <Col md="6">{this.renderCameraCard(mainCameras[i])}</Col>
          {subCameras[i] && <Col md="6">{this.renderCameraCard(subCameras[i])}</Col>}
        </Row>
      );
    }
    return cards;
  };

  renderUpdatedCameraCards = (oldCameras, newCameras) => {
    const cards = [];
    for (let i = 0; i < oldCameras.length; i++) {
      if (newCameras[i]) {
        cards.push(
          <Row key={i} className="mb-4">
            <Col md="6">{this.renderCameraCard(oldCameras[i])}</Col>
            <Col md="6">{this.renderUpdatedCameraCard(oldCameras[i], newCameras[i])}</Col>
          </Row>
        );
      }
    }
    return cards;
  };

  render() {
    const { stdOut, stdErr } = this.state;
    let errorMessage = null;
    let successMessage = null;
    // manage errors
    if (stdErr.includes("requests.exceptions.ConnectionError: HTTPConnectionPool") || stdErr.includes("introuvable")) {
      errorMessage = "Problem with HTTP port of DVR";
    } else if (stdErr.toLowerCase().includes("unauthorized")) {
      errorMessage = "Wrong password";
    } else if (stdErr.toLowerCase().includes("lowprivilege")) {
      errorMessage = "Low Privilege !";
    } else if (stdErr.toLowerCase().includes("without response")) {
      errorMessage = "No response from the video recorder";
    } else if (stdErr.toLowerCase().includes("command not found")) {
      errorMessage = "Update-dvr library not installed on the server";
    }
    // manage success
    if (stdOut.toLowerCase().includes("update completed successfully")) {
      successMessage = "Update completed successfully!";
    } else if (stdOut.toLowerCase().includes("reboot")) {
      successMessage = "DVR rebooted successfully!";
    } else if (stdOut.toLowerCase().includes("/usr/local/lib/python3.8/dist-packages")) {
      successMessage = "Update-dvr library installed on the server";
    }
    if (!stdOut.includes("reboot") && stdOut.includes("update")) {
      const { oldCameras, newCameras } = this.parseUpdateOutput(stdOut);
      const isIpCamera = stdOut.includes("camera ip");

      return (
        <div>
          <Col md="12">
            <h4 className="text-center">Raw Output</h4>
            {errorMessage && <p className="text-danger text-center">{errorMessage}</p>}
            {successMessage && <p className="text-success text-center">{successMessage}</p>}
          </Col>
          {this.renderUpdatedCameraCards(oldCameras, newCameras, isIpCamera)}
        </div>
      );
    }

    if (stdOut.includes("display")) {
      const { mainCameras, subCameras } = this.parseOutput(stdOut);
      const { primary, secondary } = this.parsePossibilities(stdOut);
      return (
        <div>
          <Col md="12">
            <h4 className="text-center">Raw Output</h4>
            {errorMessage && <p className="text-danger text-center">{errorMessage}</p>}
            {successMessage && <p className="text-success text-center">{successMessage}</p>}
          </Col>
          <Row>
            <Col md="6">{this.renderPossibilitiesCard("Main Stream", primary)}</Col>
            <Col md="6">{this.renderPossibilitiesCard("Sub Stream", secondary)}</Col>
          </Row>
          {this.renderCameraCards(mainCameras, subCameras)}
        </div>
      );
    }

    if (!stdOut.includes("reboot") && !stdOut.includes("update") && !stdOut.includes("display")) {
      const { mainCameras, subCameras } = this.parseOutput(stdOut);
      const { primary, secondary } = this.parsePossibilities(stdOut);
      return (
        <div>
          <Col md="12">
            <h4 className="text-center">Raw Output</h4>
            {errorMessage && <p className="text-danger text-center">{errorMessage}</p>}
            {successMessage && <p className="text-success text-center">{successMessage}</p>}
          </Col>
          <Row>
            <Col md="6">{this.renderPossibilitiesCard("Main Stream", primary)}</Col>
            <Col md="6">{this.renderPossibilitiesCard("Sub Stream", secondary)}</Col>
          </Row>
          {this.renderCameraCards(mainCameras, subCameras)}
        </div>
      );
    }
    return null;
  }
}

export default UpdatedvrResult;
